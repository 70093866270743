import React, { useEffect, useState } from "react";
import { Col, Container, ProgressBar, Row, Spinner } from "react-bootstrap";
import styled from "styled-components";
import { Color } from "../../theme/Color";
import { Font } from "../../theme/Font";
import Loader from "../../utils/Loader";
import { scroll } from "../../utils/scrollTop";
import { isValidEmail } from "../../utils/validation";
import ReportImg from "../../assets/small assests/data_reports.webp";
import { BigYellowLinkButton, Description, Section, Section112, SubTitle, SubmitButton, TitleSection } from "../DesignComponent/DesignComponents";
import { metaData } from "../../utils/metaData";
import Footer from "../CommonComponent/Footer";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { CircularProgressbar, CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
// import Step from "./Step";

const Quiz = () => {
    useEffect(() => {
        metaData("50506");
        scroll();
    }, []);
    const [value, setValue] = useState(15);

    const handleSliderChange = (val) => {
        setValue(val);
    };
    const [Email, setEmail] = useState("");
    const [fullName, setFullName] = useState("");
    const [Company_Name, setCompany_Name] = useState("");
    const [MyRole, SetMyRole] = useState("");
    const [TeamSize, SetTeamSize] = useState("");
    const [CompanySize, SetCompanySize] = useState("");
    const [Q1, SetQ1] = useState("");
    const [Phone, setPhone_Number] = useState("");

    const [Loading, setLoading] = useState(false);
    const [step, setStep] = useState(1);

    const [emailError, setEmailError] = useState("");
    const [FullNameError, setFullNameError] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [companyNameError, setCompanyNameError] = useState("");
    const [MyRoleError, setMyRoleError] = useState("");
    const [TeamSizeError, setMyTeamSizeError] = useState("");
    const [CompanySizeError, setCompanySizeError] = useState("");
    const [Q1Error, setQ1Error] = useState("");

    const emailRegex =
        /^[a-zA-Z0-9._%+-]+@(?!gmail\.com|yahoo\.com|hotmail\.com)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
    const nextStep = () => {
        // Check if the full name is empty
        if (step === 1) {
            if (!Q1.trim()) {
                setQ1Error("Please fill the answer.");
                return;
            }
            setStep(step + 1);
            return;
        } else if (step === 2) {
            if (!Q1.trim()) {
                setQ1Error("Please fill the answer.");
                return;
            }
            setStep(step + 1);
            return;
        } else if (step === 3) {
            if (!fullName.trim()) {
                setFullNameError("Please fill the answer.");
                return;
            }
            if (!Email.trim()) {
                setEmailError("Please fill the answer.");
                return;
            }
            if (!Phone.trim()) {
                setPhoneError("Please fill the answer.");
                return;
            }
            if (!Company_Name.trim()) {
                setCompanyNameError("Please fill the answer.");
                return;
            }
        }

        setStep(step + 1);
    };

    const prevStep = () => {
        setStep(step - 1);
    };
    const payload = {
        Email,
        fullName,
        Company_Name,
        MyRole,
        TeamSize,
        CompanySize,
        Phone,
    };

    const handleSubmit = async (e) => {
        if (!fullName.trim()) {
            setFullNameError("Please fill the answer.");
            return;
        }
        if (!Email.trim()) {
            setEmailError("Please fill the answer.");
            return;
        }
        if (!Phone.trim()) {
            setPhoneError("Please fill the answer.");
            return;
        }
        if (!Company_Name.trim()) {
            setCompanyNameError("Please fill the answer.");
            return;
        }

        e.preventDefault();

        // console.log("clicked...", payload);
        setLoading(true);
        try {
            setStep(step + 1);
            // showToastMessage();
            setLoading(false);
        } catch (error) {
            setLoading(false);
            // shownToastMessage();
        }
    };
    useEffect(() => {
        scroll();
    }, []);
    const required = <span className="text-danger">*</span>;

    const handleInput = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case "email":
                setEmail(value);
                if (!value) {
                    setEmailError("Email is required");
                } else {
                    const atIndex = value.indexOf("@");
                    if (atIndex !== -1) {
                        const dotIndex = value.indexOf(".", atIndex);
                        if (dotIndex !== -1) {
                            setCompany_Name(value.substring(atIndex + 1, dotIndex));
                        }
                    }
                    if (!isValidEmail(value)) {
                        setEmailError("Invalid email please enter business email");
                    } else {
                        setEmailError("");
                    }
                }
                break;
            case "fullname":
                setFullName(value);
                if (!value) {
                    setFullNameError("fullname is required");
                } else {
                    setFullNameError("");
                }
                break;
            case "company":
                setCompany_Name(value);
                if (!value) {
                    setCompanyNameError("Company name is required");
                } else {
                    setCompanyNameError("");
                }
                break;
            case "myrole":
                SetMyRole(value);
                if (!value) {
                    setMyRoleError("my Role is required");
                } else {
                    setMyRoleError("");
                }
                break;
            case "teamSize":
                SetTeamSize(value);
                if (!value) {
                    setMyTeamSizeError("Team size is required");
                } else {
                    setMyTeamSizeError("");
                }
                break;
            case "companySize":
                SetCompanySize(value);
                if (!value) {
                    setCompanySizeError("company size is required");
                } else {
                    setCompanySizeError("");
                }
                break;
            case "Q1":
                SetQ1(value);
                if (!value) {
                    setQ1Error("Answer is required");
                } else {
                    setQ1Error("");
                }
                break;
            case "phone":
                setPhone_Number(value);
                if (!value) {
                    setPhoneError("Phone number is required");
                } else {
                    setPhoneError("");
                }
                break;

            default:
                break;
        }
    };
    const progress = (step / 3) * 100;
    const b1 = 58
    const b2 = 82
    const b3 = 34
    const b4 = 58

    return (
        <>
            <Section112
                className="quiz-sec d-flex align-items-center"
                style={{ backgroundColor: Color.theme_four }}
            >
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-6">
                            {step === 3 && (
                                <img src={ReportImg} alt={"ReportImg"} className="w-100" />
                            )}
                            {step !== 3 && (
                                <SubTitle className="sub-title mb-2 text-center">Quiz</SubTitle>
                            )}
                            <TitleSection className="mb-3 text-center">
                                Lorem ipsum dolor sit amet
                            </TitleSection>
                            <Description
                                className="mb-4 text-center"
                                style={{ color: Color.lighterGrey }}
                            >
                                Lorem ipsum dolor sit amet consectetur. Et duis placerat risus magna in leo. Laoreet ultricies odio interdum commodo. Felis massa risus tellus egestas felis et mauris ut. Sit rhoncus turpis nulla arcu facilisi aliquet dolor proin.
                            </Description>
                            <hr className="w-75 mx-auto" />
                            {step !== 3 && step !== 4 && (
                                <ProgressBar now={progress} className="mb-4" />
                            )}
                            {step === 1 && (
                                <div className="step-1">
                                    <div>
                                        <div className="mb-3">
                                            <Label className="form-label">
                                                Question 1
                                            </Label>
                                            <select
                                                name="Q1"
                                                className="form-select p-2"
                                                value={Q1}
                                                onChange={handleInput}
                                            >
                                                <option value="" disabled selected>
                                                    Dropdown
                                                </option>
                                                <option value="5-10">5-10</option>
                                                <option value="11-15">11-15</option>
                                                <option value="15-20">15-20</option>
                                                <option value="21-25">21-25</option>
                                                <option value="25+">25+</option>
                                            </select>{" "}
                                            {Q1Error && <Error> {Q1}</Error>}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">
                                                Question 2
                                            </Label>
                                            <div className="">
                                                <input type="radio" className="btn-check" name="options" id="option1" autocomplete="off" />
                                                <RadioButton className="btn" for="option1">option 1</RadioButton>

                                                <input type="radio" className="btn-check" name="options" id="option2" autocomplete="off" />
                                                <RadioButton className="btn" for="option2">option 2</RadioButton>

                                                <input type="radio" className="btn-check" name="options" id="option3" autocomplete="off" />
                                                <RadioButton className="btn" for="option3">option 3</RadioButton>

                                                <input type="radio" className="btn-check" name="options" id="option4" autocomplete="off" />
                                                <RadioButton className="btn" for="option4">option 4</RadioButton>
                                            </div>{" "}
                                            {Q1Error && <Error> {Q1}</Error>}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Question 3</Label>
                                            <div className="p-4 bg-white">
                                                <div className="text-center my-2">{value}</div>
                                                <Slider
                                                    min={10}
                                                    max={40}
                                                    defaultValue={15}
                                                    value={value}
                                                    onChange={handleSliderChange}
                                                    marks={{ 10: '10', 15: '15', 20: '20', 25: '25', 30: '30', 35: '35', 40: '40' }}
                                                    step={null}
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">
                                                Question 4
                                            </Label>
                                            <Input
                                                type="text"
                                                name="Q1"
                                                value={Q1}
                                                onChange={handleInput}
                                                className="form-control p-2"
                                            />{" "}
                                            {Q1Error && <Error> {Q1}</Error>}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">
                                                Question 5
                                            </Label>
                                            <textarea
                                                name="Q1"
                                                value={Q1}
                                                onChange={handleInput}
                                                className="form-control p-2"
                                            />{" "}
                                            {Q1Error && <Error> {Q1}</Error>}
                                        </div>
                                        <SubmitButton
                                            onClick={nextStep}
                                            type="submit"
                                            className="btn w-100 mb-4"
                                        >
                                            {Loading ? "Loading..." : "Continue"}
                                        </SubmitButton>
                                    </div>
                                </div>
                            )}
                            {step === 2 && (
                                <div className="step-2">
                                    <div>
                                        <div className="mb-3">
                                            <Label className="form-label">
                                                Question 1
                                            </Label>
                                            <select
                                                name="Q1"
                                                className="form-select p-2"
                                                value={Q1}
                                                onChange={handleInput}
                                            >
                                                <option value="" disabled selected>
                                                    Dropdown
                                                </option>
                                                <option value="5-10">5-10</option>
                                                <option value="11-15">11-15</option>
                                                <option value="15-20">15-20</option>
                                                <option value="21-25">21-25</option>
                                                <option value="25+">25+</option>
                                            </select>{" "}
                                            {Q1Error && <Error> {Q1}</Error>}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">
                                                Question 2
                                            </Label>
                                            <div className="">
                                                <input type="radio" className="btn-check" name="options" id="option1" autocomplete="off" />
                                                <RadioButton className="btn" for="option1">option 1</RadioButton>

                                                <input type="radio" className="btn-check" name="options" id="option2" autocomplete="off" />
                                                <RadioButton className="btn" for="option2">option 2</RadioButton>

                                                <input type="radio" className="btn-check" name="options" id="option3" autocomplete="off" />
                                                <RadioButton className="btn" for="option3">option 3</RadioButton>

                                                <input type="radio" className="btn-check" name="options" id="option4" autocomplete="off" />
                                                <RadioButton className="btn" for="option4">option 4</RadioButton>
                                            </div>{" "}
                                            {Q1Error && <Error> {Q1}</Error>}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Question 3</Label>
                                            <div className="p-4 bg-white">
                                                <div className="text-center my-2">{value}</div>
                                                <Slider
                                                    min={10}
                                                    max={40}
                                                    defaultValue={15}
                                                    value={value}
                                                    onChange={handleSliderChange}
                                                    marks={{ 10: '10', 15: '15', 20: '20', 25: '25', 30: '30', 35: '35', 40: '40' }}
                                                    step={null}
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">
                                                Question 4
                                            </Label>
                                            <Input
                                                type="text"
                                                name="Q1"
                                                value={Q1}
                                                onChange={handleInput}
                                                className="form-control p-2"
                                            />{" "}
                                            {Q1Error && <Error> {Q1}</Error>}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">
                                                Question 5
                                            </Label>
                                            <textarea
                                                name="Q1"
                                                value={Q1}
                                                onChange={handleInput}
                                                className="form-control p-2"
                                            />{" "}
                                            {Q1Error && <Error> {Q1}</Error>}
                                        </div>
                                        <div className="d-flex gap-3 ">
                                            <BackButton
                                                type="Back"
                                                onClick={prevStep}
                                                className="btn border w-100 mb-4"
                                            >
                                                {Loading ? "Loading..." : "Back"}
                                            </BackButton>
                                            <SubmitButton
                                                onClick={nextStep}
                                                type="submit"
                                                className="btn w-100 mb-4"
                                            >
                                                {Loading ? "Loading..." : "Continue"}
                                            </SubmitButton>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {step === 3 && (
                                <div className="thank-you-msg">
                                    <div>
                                        <div className="mb-3">
                                            <Label className="form-label">
                                                Name:
                                            </Label>
                                            <Input
                                                type="text"
                                                name="fullname"
                                                value={fullName}
                                                onChange={handleInput}
                                                className="form-control p-2"
                                            />{" "}
                                            {FullNameError && <Error> {FullNameError}</Error>}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">
                                                Email ID:
                                            </Label>
                                            <Input
                                                type="email"
                                                name="email"
                                                className="form-control p-2"
                                                value={Email}
                                                onChange={handleInput}
                                            />{" "}
                                            {emailError && <Error> {emailError}</Error>}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">
                                                Phone:
                                            </Label>
                                            <PhoneInput
                                                country="us"
                                                value={Phone}
                                                onChange={(phone) =>
                                                    handleInput({ target: { name: "phone", value: phone } })
                                                }
                                                inputStyle={{
                                                    width: "100%",
                                                    paddingTop: "10px",
                                                    paddingBottom: "10px",
                                                    borderRadius: "2px",
                                                    height: "unset",
                                                }}
                                                dropdownStyle={{
                                                    color: "black",
                                                }}
                                            />
                                            {phoneError && <Error>{phoneError}</Error>}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">
                                                Company Name:
                                            </Label>
                                            <Input
                                                type="text"
                                                name="Company_Name"
                                                value={Company_Name}
                                                onChange={handleInput}
                                                className="form-control p-2"
                                            />{" "}
                                            {companyNameError && <Error> {companyNameError}</Error>}
                                        </div>
                                        <div className="d-flex gap-3 ">
                                            <SubmitButton
                                                onClick={nextStep}
                                                type="submit"
                                                className="btn w-100 mb-4"
                                            >
                                                {Loading ? "Loading..." : "Submit"}
                                            </SubmitButton>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {step === 4 && (
                                <div className="report-sec">
                                    <div className="d-flex gap-5">
                                        <div className="progress-item d-flex justify-content-center flex-wrap">
                                            <CircularProgressbar
                                                value={58}
                                                text={"58%"}
                                                background
                                                styles={buildStyles({
                                                    pathColor: "#FFC000",
                                                    textColor: "#FFC000",
                                                    backgroundColor: "#FFC0001F",
                                                    trailColor: "transparent",
                                                })}
                                            />
                                            <Label className="text-center">Area - 1</Label>
                                        </div>
                                        <div className="progress-item d-flex justify-content-center flex-wrap">
                                            <CircularProgressbar
                                                value={82}
                                                text={"82%"}
                                                background
                                                styles={buildStyles({
                                                    pathColor: "#4ABA00",
                                                    textColor: "#4ABA00",
                                                    backgroundColor: "#4ABA001F",
                                                    trailColor: "transparent",
                                                })}
                                            />
                                            <Label className="text-center">Area - 2</Label>
                                        </div>
                                        <div className="progress-item d-flex justify-content-center flex-wrap">
                                            <CircularProgressbar
                                                value={34}
                                                text={"34%"}
                                                background
                                                styles={buildStyles({
                                                    pathColor: "#FF3D00",
                                                    textColor: "#FF3D00",
                                                    backgroundColor: "#FF3D001F",
                                                    trailColor: "transparent",
                                                })}
                                            />
                                            <Label className="text-center">Area - 3</Label>
                                        </div>
                                        <div className="progress-item d-flex justify-content-center flex-wrap">
                                            <CircularProgressbar
                                                value={58}
                                                text={"58%"}
                                                background
                                                styles={buildStyles({
                                                    pathColor: "#FFC000",
                                                    textColor: "#FFC000",
                                                    backgroundColor: "#FFC0001F",
                                                    trailColor: "transparent",
                                                })}
                                            />
                                            <Label className="text-center">Area - 3</Label>
                                        </div>
                                    </div>
                                    <hr className="w-75 mx-auto" />
                                    <div className="w-50 mx-auto py-4">
                                        <CircularProgressbarWithChildren
                                            value={100}
                                            styles={buildStyles({
                                                pathColor: "#808080",
                                                trailColor: "#eee",
                                            })}
                                        >
                                            <CircularProgressbarWithChildren
                                                value={((b4 * 25) / 100) + 75}
                                                circleRatio={1}
                                                styles={buildStyles({
                                                    pathColor: "#FFC000",
                                                    trailColor: "#eee",
                                                })}
                                            >
                                                <CircularProgressbarWithChildren
                                                    value={((b3 * 34) / 100) + 66}
                                                    circleRatio={0.75}
                                                    styles={buildStyles({
                                                        pathColor: "#FF3D00",
                                                        trailColor: "#eee",
                                                    })}
                                                >
                                                    <CircularProgressbarWithChildren
                                                        value={((b2 * 50) / 100) + 50}
                                                        circleRatio={0.5}
                                                        styles={buildStyles({
                                                            pathColor: "#4ABA00",
                                                            trailColor: "#eee",
                                                            // zIndex: 222,
                                                        })}
                                                    >
                                                        <CircularProgressbar
                                                            value={b1}
                                                            text={`${Math.round(((b1 + b2 + b3 + b4) / 400) * 100)}%`}
                                                            circleRatio={0.25}
                                                            background
                                                            styles={buildStyles({
                                                                pathColor: "#FFC000",
                                                                textColor: "#FFC000",
                                                                backgroundColor: "#FFC0001F",
                                                                trailColor: "transparent",
                                                            })}
                                                        />
                                                    </CircularProgressbarWithChildren>
                                                </CircularProgressbarWithChildren>
                                            </CircularProgressbarWithChildren>
                                        </CircularProgressbarWithChildren>
                                        <TitleSection className="my-4 text-center">
                                        Overall Score
                                        </TitleSection>
                                        <BigYellowLinkButton to="/schedule-demo" className="w-100" style={{minWidth:"unset"}}>CTA</BigYellowLinkButton>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Section112>
            <Footer />
        </>
    );
};
export default Quiz;
const Tit = styled.label`
  text-align: start;
  justify-content: start;
  color: ${Color.white};
  font-family: ${Font.basel};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  margin-bottom: 8px;
  text-align: start;
`;
const BackButton = styled.button`
  font-size: 18px;
  border-radius: 4px;
  padding: 10px;
  font-family: ${Font.basel};
  font-weight: bold;
  &:hover {
    // background-color: ${Color.white} !important;
    // color: ${Color.theme_first} !important;
  }
  @media (max-width: 767px) {
    font-size: 16px;
  }
`;
const SmallText = styled.p`
  font-size: 14px;
  font-family: ${Font.basel};
`;
const Label = styled.label`
  font-size: 16px;
  font-family: ${Font.basel};
  font-weight:bold;
`;
const Input = styled.input`
  border-radius: 4px;
`;
const Select = styled.select`
  border-radius: 4px;
`;
const Error = styled.p`
  color: red;
  font-size: 14px;
  // padding: 10px 2px 0 0;
  margin: 0 !important;
`;
const RadioButton = styled.label`
background-color: ${Color.white};
border: 1px solid #00000033;
color: #6B7280;
width:100%;
padding:8px;
&:not(:last-child){
margin-bottom:8px;
}
.btn-check:checked+&{
background-color: ${Color.yelow};
border: 1px solid ${Color.yelow};
color: ${Color.text};
}
.btn-check+&:hover{
background-color: ${Color.yelow};
border: 1px solid ${Color.yelow};
color: ${Color.text};
}
`;
