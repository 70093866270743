// export const isValidEmail = (email) => {
//   const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//   return emailPattern.test(email);
// };

export const isValidEmail = (email) => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@(?!gmail\.com|yahoo\.com|hotmail\.com)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
  return emailPattern.test(email);
};

