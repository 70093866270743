import { Color } from "../../theme/Color";
import { styled } from "styled-components";
import { Font } from "../../theme/Font";
import { Link } from "react-router-dom";

export const SubTitle = styled.p`
  font-size: calc(calc((100vw - 428px) / 1012 * 1) + 11px);
  text-transform: uppercase;
  font-family: ${Font.regular};
  font-weight: 500;
  line-height: calc(calc((100vw - 428px) / 1012 * 1) + 15px);
  letter-spacing: calc(calc((100vw - 428px) / 1012* 0) + 1.25px);
  @media (min-width: 1440px) {
    font-size: .75rem;
    line-height: 1rem;
    letter-spacing: .078125rem;
  }
    @media (min-width: 1px) and (max-width: 428px) {
      font-size: .6875rem;
      line-height: .9375rem;
      letter-spacing: .078125rem;
    }
  }
`;
export const SectionTitle = styled.h1`
  font-family: ${Font.bold};
  // text-transform: capitalize;
  font-size: calc(calc((100vw - 428px) / 1012 * 12) + 36px);
  line-height: calc(calc((100vw - 428px) / 1012 * 11) + 39px);
  letter-spacing: calc(calc((100vw - 428px) / 1012 * 0.5) + -1.5px);
  @media (min-width: 1440px) {
    font-size: 3rem;
    letter-spacing: -0.0625rem;
    line-height: 3.125rem;
  }
  @media (min-width: 1px) and (max-width: 428px) {
    font-size: 2.25rem;
    letter-spacing: -0.09375rem;
    line-height: 2.4375rem;
  }
`;
export const TitleSection = styled.h2`
  font-size: calc(calc((100vw - 428px) / 1012 * 8) + 28px);
  line-height: calc(calc((100vw - 428px) / 1012 * 10) + 32px);
  letter-spacing: calc(calc((100vw - 428px) / 1012 * 0) + -1px);
  font-family: ${Font.bold};
  // text-transform: capitalize;
  @media (min-width: 1440px) {
    font-size: 2.25rem;
    line-height: 2.625rem;
    letter-spacing: -0.0625rem;
  }
  @media (min-width: 1px) and (max-width: 428px) {
    font-size: 1.75rem;
    line-height: 2rem;
    letter-spacing: -0.0625rem;
  }
`;
export const TitleCTASec = styled.h2`
  font-size: 22px;
  line-height: 26px;
  letter-spacing: calc(calc((100vw - 428px) / 1012 * 0) + .25px);
  font-family: ${Font.bold};
  // text-transform: capitalize;
  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.0625rem;
  }
  @media (min-width: 1px) and (max-width: 428px) {
    letter-spacing: .015625rem;
  }
`;
export const TitleInnerSection = styled.h3`
font-size: calc(calc((100vw - 428px) / 1012* 10) + 20px);
line-height: calc(calc((100vw - 428px) / 1012* 10) + 26px);
letter-spacing: calc(calc((100vw - 428px) / 1012* -.2) + .2px);
  font-family: ${Font.bold};
  @media (min-width: 1440px) {
    font-size: 1.875rem;
    line-height: 2.25rem;
    letter-spacing: 0;
  }
  @media (min-width: 1px) and (max-width: 428px) {
    font-size: 1.25rem;
    line-height: 1.625rem;
    letter-spacing: .0125rem;
  }
`;
export const Section = styled.section`
  min-height: calc(100vh - 90px);
  padding-top: 40px;
  padding-bottom: 40px;
  @media (max-width: 767px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @media (max-width: 991px) {
    padding-bottom: 70px;
  }
`;
export const Section112 = styled.section`
  padding-top: 112px;
  padding-bottom: 112px;
  @media (max-width: 767px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;
export const Section120 = styled.section`
  padding-top: 120px;
  padding-bottom: 120px;
  @media (max-width: 767px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;
export const Section80 = styled.section`
  padding-top: 80px;
  padding-bottom: 80px;
  @media (max-width: 767px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;
export const Description = styled.p`
opacity:0.75;
  font-size: calc(calc((100vw - 428px) / 1012 * 2) + 16px);
  line-height: calc(calc((100vw - 428px) / 1012 * 4) + 22px);
  letter-spacing: calc(calc((100vw - 428px) / 1012 * 0) + 0.25px);
  font-family: ${Font.basel};
  @media (min-width: 1440px) {
    font-size: 1.125rem;
    line-height: 1.625rem;
    letter-spacing: 0.015625rem;
  }
  @media (min-width: 1px) and (max-width: 428px) {
    font-size: 1rem;
    line-height: 1.375rem;
    letter-spacing: 0.015625rem;
  }
`;

export const SubmitButton = styled.button`
text-transform: capitalize;
  background: ${Color.yelow};
  border-radius: 2px;
  border: 0;
  box-shadow: none;
  color: #000;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 24px;
  padding: 16px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  width: 100%;
  &:hover {
    background: ${Color.yelow} !important;
    color: #000;
  }
  @media (max-width: 767px) {
    font-size: 16px;
  }
`;
export const ButtonDemo = styled(Link)`
text-transform: capitalize;
  background-color: ${Color.yelow};
  color: ${Color.theme_first};
  border-radius: 2px;
  border: 1px solid ${Color.yelow};
  min-width: 40%;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 24px;
  padding: 16px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  &:hover {
    background-color: ${Color.yelow};
    color: ${Color.theme_first};
  }
  @media (max-width: 767px) {
    font-size: 16px;
  }
`;
export const BannerButton = styled(Link)`
text-transform: capitalize;
  font-family: ${Font.basel};
  background-color: ${Color.yelow};
  color: ${Color.theme_first};
  border: 1px solid ${Color.yelow};
  min-width: 200px;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.25px;
  line-height: 24px;
  padding: 12px 8px;
  border-radius: 2px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  transition: box-shadow .3s cubic-bezier(.25, .46, .45, .94);
  &:hover {
    background-color: ${Color.yelow};
    color: ${Color.theme_first};
    box-shadow: 0 1px 5px rgba(150,150,150,.5);
  }
  @media (max-width: 767px) {
    font-size: 16px;
  }
`;
export const BigYellowLinkButton = styled(Link)`
text-transform: capitalize;
  font-family: ${Font.basel};
  background-color: ${Color.yelow};
  color: ${Color.theme_first};
  border: 1px solid ${Color.yelow};
  min-width: 300px;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.25px;
  line-height: 24px;
  padding: 15px;
  border-radius: 2px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  transition: box-shadow .3s cubic-bezier(.25, .46, .45, .94);
  &:hover {
    background-color: ${Color.yelow};
    color: ${Color.theme_first};
    box-shadow: 0 1px 5px rgba(150,150,150,.5);
  }
  @media (max-width: 767px) {
    font-size: 16px;
  }
`;
export const BigYellowButton = styled.button`
text-transform: capitalize;
  font-family: ${Font.basel};
  background-color: ${Color.yelow};
  color: ${Color.theme_first};
  border: 1px solid ${Color.yelow};
  min-width: 300px;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.25px;
  line-height: 24px;
  padding: 15px;
  border-radius: 2px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  transition: box-shadow .3s cubic-bezier(.25, .46, .45, .94);
  &:hover {
    background-color: ${Color.yelow} !important;
    color: ${Color.theme_first};
    box-shadow: 0 1px 5px rgba(150,150,150,.5);
  }
  @media (max-width: 767px) {
    font-size: 16px;
  }
`;
export const BigWhiteButton = styled(Link)`
text-transform: capitalize;
  font-family: ${Font.basel};
  background-color: transparent;
  color: ${Color.white};
  border: 1px solid ${Color.yelow};
  min-width: 300px;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.25px;
  line-height: 24px;
  padding: 15px;
  border-radius: 2px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  &:hover {
    background-color: ${Color.yelow};
    color: ${Color.theme_first} !important;
    border-color: ${Color.yelow};
  }
  @media (max-width: 767px) {
    font-size: 16px;
  }
`;

export const CardTitle = styled.p`
  font-size: 18px;
  font-family: ${Font.basel};
  font-weight: bold;
  @media (min-width: 1440px) {
    letter-spacing: .015625rem;
    line-height: 1.625rem;
    font-size: 1.125rem;
  }
  @media (min-width: 1px) and (max-width: 428px) {
    letter-spacing: .015625rem;
    line-height: 1.375rem;
    font-size: 1rem;
  }
`;
export const CardContent = styled.p`
  opacity:0.75;
  font-family: ${Font.basel};
  font-size: calc(calc((100vw - 428px) / 1012* 1) + 14px);
    line-height: calc(calc((100vw - 428px) / 1012* 2) + 20px);
    letter-spacing: calc(calc((100vw - 428px) / 1012* 0) + .25px);
    @media (min-width: 1440px) {
      font-size: .9375rem;
      line-height: 1.375rem;
      letter-spacing: .015625rem;
    }
    @media (min-width: 1px) and (max-width: 428px) {
      letter-spacing: .015625rem;
      line-height: 1.25rem;
      font-size: .875rem;
  }
`;
