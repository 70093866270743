import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { landingPageReducer } from "./reducers/landingPageReducer";
import { navarReducer } from "./reducers/navbarReducer";
import { aboutPageReducer } from "./reducers/aboutPageReducer";
import { whatIsLeanReducer } from "./reducers/whatIsLeanReducer";
import { reportReducer } from "./reducers/reportReducer";
import { blogsReducer } from "./reducers/blogReducer";
import attachmentReducer from "./reducers/attachmentReducer";
import campaignReducer from "./reducers/campaignReducer";
import pointLeaderboardReducer from "./reducers/pointLeaderboardReducer";
import suggestionOperationReducer from "./reducers/suggestionOperationReducer";
import suggestionPlatformReducer from "./reducers/suggestionPlatformReducer";
import lmsPageReducer from "./reducers/lmsPageReducer";
import rolespermissionsReducer from "./reducers/rolespermissionsReducer";
import templatebuilderReducer from "./reducers/templatebuilderReducer";
import safetyimprovementReducer from "./reducers/safetyimprovementReducer";
import partnersPageReducer from "./reducers/partnersPageReducer";
import qamatrixPageReducer from "./reducers/qamatrixPageReducer";
import contactPageReducer from "./reducers/contactPageReducer";
import careersPageReducer from "./reducers/careersPageReducer";
import continuousImprovementReducer from "./reducers/continuousImprovementReducer";
import pressPageReducer from "./reducers/pressPageReducer";
import issueTrackerReducer from "./reducers/issueTrackerReducer";
import pricingPageReducer from "./reducers/pricingPageReducer";
import { blogsList } from "./actions/blogAction";
import { blogListReducer } from "./reducers/blogListReducer";
import qualityimprovementReducer from "./reducers/qualityimprovementReducer";
import operationalexcellenceReducer from "./reducers/operationalexcellenceReducer";
import costreductionReducer from "./reducers/costreductionReducer";
import workforceenablementReducer from "./reducers/workforceenablementReducer";
import legalReducer from "./reducers/legalReducer";
import gembawalkerReducer from "./reducers/gembawalkerReducer";
import cilrReducer from "./reducers/cilrReducer";
import customizablefieldsReducer from "./reducers/customizablefieldsReducer";
import marketplaceReducer from "./reducers/marketplaceReducer";
import jobcovermatrixReducer from "./reducers/jobcovermatrixReducer";
import lossandcostReducer from "./reducers/lossandcostReducer";

const reducer = combineReducers({
  landingPage: landingPageReducer,
  aboutPagePage: aboutPageReducer,
  whatIsLeanPage: whatIsLeanReducer,
  report: reportReducer,
  blogs: blogsReducer,
  allblogs: blogListReducer,
  attachment: attachmentReducer,
  campaign: campaignReducer,
  pointLeader: pointLeaderboardReducer,
  suggestionOperation: suggestionOperationReducer,
  suggestionPlatform: suggestionPlatformReducer,
  lmsPage: lmsPageReducer,
  rolespermissions: rolespermissionsReducer,
  templatebuilder: templatebuilderReducer,
  partnersPage: partnersPageReducer,
  qamatrixPage: qamatrixPageReducer,
  contactPage: contactPageReducer,
  pricingPage: pricingPageReducer,
  careersPage: careersPageReducer,
  viewoppPage: careersPageReducer,
  continuousimprovementPage: continuousImprovementReducer,
  Press: pressPageReducer,
  safetyimprovement: safetyimprovementReducer,
  qualityimprovement: qualityimprovementReducer,
  operationalexcellence: operationalexcellenceReducer,
  costreduction: costreductionReducer,
  workforceenablement: workforceenablementReducer,
  legalPage: legalReducer,
  isNavOpen: navarReducer,
  issueTracker: issueTrackerReducer,
  gembawalker: gembawalkerReducer,
  cilr: cilrReducer,
  customizablefields: customizablefieldsReducer,
  marketplace: marketplaceReducer,
  jobcovermatrix: jobcovermatrixReducer,
  lossandcost: lossandcostReducer,
});

let initialState = {};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
